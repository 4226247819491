
.container {
    display: flex;
}

.aboutTextWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 86px;
}

.aboutTextTitle {
    margin-bottom: 20px;
    color: #af4cab;
}

.aboutText {
    white-space: pre-wrap;
    font-size: 18px;
    padding: 0;
}

.aboutImage {
    max-width: 432px;
    display: flex;
    border-radius: 6px;
    margin: 86px 0 86px auto;
    min-width: 0;
    box-shadow: 0px 10px 30px 0 rgba(189, 189, 189, 0), 0 10px 30px rgba(0, 0, 0, 0.2);
}

.aboutImageWrapper {
    width: 100%;
    min-width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 976px) {
    .container{
        flex-direction: column;
    }
    .aboutTextWrapper {
        margin-top: 36px;
    }
    .aboutImage {
        margin: 60px 0 60px 0;
    }
}

@media (max-width: 432px) {
    .aboutImage {
        max-width: 100%;
    }
}

.galleryLink {
    color: black;
    font-weight: 600;
}

ol {
    list-style: none;
    counter-reset: my-awesome-counter;
}
ol li {
    counter-increment: my-awesome-counter;
}
ol li::before {
    content: counter(my-awesome-counter) ". ";
    font-weight: bold;
}
