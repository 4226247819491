.name {
    margin-top: auto;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
}

.container {
    flex: 1 1 260px;
    height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.image {
    width: 250px;
    object-fit: contain;
    flex: 1;
}
