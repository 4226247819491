.container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.main {
    margin-top: 110px;
    flex-grow: 1;
}

@media screen and (max-width: 500px) {
    .main {
        margin-top: 90px;
    }
}
