
.container {
    margin-bottom: 50px;
}

.aboutTextWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.aboutTextTitle {
    margin-bottom: 20px;
    color: #af4cab;
    align-self: flex-start;
}

.aboutText {
    white-space: pre-wrap;
    font-size: 18px;
}

.aboutImage {
    width: 450px;
    max-width: 100%;
}

.aboutImageWrapper {
    width: 100%;
    min-width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 976px) {
    .container{
        flex-direction: column;
    }

}

@media (max-width: 432px) {
    .aboutImage {
        max-width: 100%;
    }
}

.galleryLink {
    color: black;
    font-weight: 600;
}

.flex {
    display: flex;
    margin-top: 60px;
    margin-bottom: 20px;
}

.image {
    display: none;
    max-width: 100%;
}

@media (max-width: 1080px) {
    .container{
        flex-direction: column;
    }
    .image {
        display: flex;
        align-self: center;
    }
    .aboutImageWrapper {
        display: none;
    }
    .button {
        align-self: center;
    }
    .aboutImage {
        margin: 60px 0 60px 0;
    }
}
