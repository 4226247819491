.container {
    position: relative;
    width: 30px;
    margin: 0 auto;
}

.animation {
  animation: shake 2s cubic-bezier(.36,.07,.19,.97) infinite;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  5%, 45% {
    transform: translate3d(-1px, 0, 0);
  }

  10%, 40% {
    transform: translate3d(2px, 0, 0);
  }

  15%, 25%, 35% {
    transform: translate3d(-4px, 0, 0);
  }

  20%, 30% {
    transform: translate3d(4px, 0, 0);
  }

  50% {
    transform: translate3d(0, 0, 0);
  }
}

.count {
  position: absolute;
  top: -6px;
  right: -6px;
  width: 16px;
  height: 16px;
  background: #F44336;
  color: white;
  font-size: 14px;
  text-align: center;
  border-radius: 50%;
}
