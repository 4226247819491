.contacts {
}

.contactsHeader {
    font-size: 30px;
    padding: 20px 100px;
    text-transform: uppercase;
    margin-top: 12px;
    border-radius: 20px;
}

.container {
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contactsWrapper {
    display: flex;
    width: 100%;
    height: 100%;
}

.contactsWrapper>* {
    min-width: 50%;
}

.communication,
.location {
    display: flex;
    flex-direction: column;
    margin: 24px 0 48px 0;
}

.communication {
    border-right: 1px solid #ccc;
    padding-top: 24px;
}

.communicationContent {
    font-size: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.communicationContent>* {
    margin-bottom: 10px;
}

.communication h4 {
    text-transform: uppercase;
    color: #af4cab;
    font-size: 14px;
    font-style: normal;
    margin-bottom: 5px;
}

.communication p {
    margin-bottom: 50px;
    font-size: 30px;
    font-weight: 300;
}

.communicationContent > a {
    margin-bottom: 50px;
    font-size: 30px;
    font-weight: 300;
    text-decoration: none;
    display: block;
    color: inherit;
}


.location p {
    font-size: 30px;
    font-weight: 300;
}

.communicationSupport {
    font-size: 40px;
}

.communicationSupport>* {
    text-decoration: none;
    margin-right: 20px;
}

.communicationSupport>*:nth-child(1) {
    color: #665cac;
}

.communicationSupport>*:nth-child(2) {
    color: #00e676;
}

.communicationSupport>*:nth-child(3) {
    color: #00aff0;
}

.communicationSupport>*:nth-child(4) {
    color: #0088cc;
}

.locationContent {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.locationAdress {
    font-size: 24px;
    font-style: normal;
    margin-bottom: 30px;
    line-height: 31px;
    width: 100%;
    text-align: center;
}

.map {
    height: 400px;
    width: 90%;
}

@media (max-width: 1400px) {
    .map {
        height: 350px;
    }
}

@media (max-width: 1023px) {
    .contactsWrapper {
        flex-direction: column;
    }
    .communication {
        border-right: none;
    }
    .locationContent {
        align-items: center;
    }
    .map {
        height: 400px;
    }
}

@media (max-width: 780px) {
    .map {
        width: 100%;
    }
}

@media (max-width: 361px) {
    .contactsHeader {
        padding: 20px 80px;
    }
}
