.container {
    height: 80px;
    background-color: #388E3C;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: white;


}

.logo {
    margin: 12px 0;
}

.link {
    margin-top: 12px;
    a {
        color: white;
        text-decoration: none;
    }
}
