.delivery {
    background-color: #ffffff;

}
.container {
  display: flex;
  flex-direction: column;
}

.text {
  font-size: 18px;
}

.header {
    font-size: 30px;
    padding: 20px 0px;
    text-transform: uppercase;
    margin-top: 12px;
    border-radius: 20px;
    text-align: center;
}

.headline {
    color: #af4cab;
}

.content {
    display: flex;
}
.textWrapper {
    display: flex;
    flex-direction: column;
    margin: 64px 0 0 0;
}

.headline {
    margin-bottom: 20px;
}

.image {
    max-width: 500px;
    display: flex;
    border-radius: 6px;
    margin: 64px auto 86px 0;
    min-width: 0;
    box-shadow: 0px 10px 30px 0 rgba(189, 189, 189, 0), 0 10px 30px rgba(0, 0, 0, 0.2);
}

.imageWrapper {
    min-width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1080px) {
  .content {
    flex-direction: column;
  }

  .image {
    margin: 64px auto 86px auto;
}

  .textWrapper {
    order: 0;
    margin: 24px 0 0 0;
  }
  .imageWrapper {
    order: 1;
  }

}

@media (max-width: 550px) {
  .image {
      max-width: 100%;
  }
}
