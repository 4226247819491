.mainHeader {
    height: 500px;
    background-image: url('../../assets/images/tulips-background.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 80%;
}

.mainHeaderText {
  width: 600px;
    white-space: pre-wrap;
    color: #ffffff;
    font-weight: 300;
    background-color: rgba(89, 89, 89, 0.5);
    padding: 20px;
    border-radius: 6px;
    font-size: 24px;
    margin-top: 48px;
}

.headline {
  font-size: 28px;
  font-weight: bold;
  color: #efefef;
}

.headline2 {
  font-size: 24px;
  font-weight: 300;
  color: #efefef;
}

.mainHeaderContainer {
  display: flex
}

.advantagesRow {
    display: flex;
    width: 100%;
    justify-content: space-around;
}

@media (max-width: 796px) {
  .mainHeader {
    height: 500px;
  }
}

@media (max-width: 500px) {
  .mainHeaderText {
    width: auto;
    font-size: 18px;
    margin-top: 24px;
  }
  .mainHeader {
    height: 340px;
  }
  .headline, .headline2 {
    font-size: 18px;
  }
}

@media (max-width: 380px) {
  .mainHeaderText, .headline2 {
    font-size: 16px;
  }
}

.catalogLink {
  text-decoration: none;
  font-weight: 600;
  color: #ffffff;
  text-decoration: underline;
}
