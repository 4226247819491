body {
    font-family: 'Montserrat', sans-serif;
    max-width: 2000px;
    margin: 0 auto;
    background-color: #fafafa;
    height: 100%;
}

p, h1, h2, h3, h4 {
    margin: 0;
}

* {
    box-sizing: border-box;
}
