.container {
    background-color: #ffffff;
    padding-bottom: 70px;
}

.header {
    font-size: 30px;
    padding: 20px 0px;
    text-transform: uppercase;
    margin-top: 12px;
    border-radius: 20px;
    text-align: center;
}


.content {
    display: flex;
    flex-wrap: wrap;
}
