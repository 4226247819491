.headerButton:hover {
  cursor: pointer;
}

.headerButton {
  text-decoration: none;
  color: #FFFFFF;
  margin-left: 20px;
}

.headerButton:last-child {
  padding-right: 0;
}

.linkLabel {
  padding-bottom: 3px;
}

.activeLink {
    border-bottom: 2px solid white;
}

.headerButton .activeLink {
    margin-bottom: -2px;
}

@media screen and (max-width: 820px) {
  .headerButton {
    display: none;
  }
}

@media screen and (max-width: 820px) {
  :global(.responsive) .headerButton {
    display: block;
    padding: 12px 0;
  }
  :global(.responsive) .linkLabel {
    font-size: 24px;
    display: inline;
  }
  :global(.responsive) .headerButton:not(:first-child):not(:last-child) {
    text-align: center;
    width: 100%;
  }
}

