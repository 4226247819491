.header {
    width: 100%;
    height: 110px;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #4CAF50;
    color: #FFFFFF;
    font-size: 24px;
    box-shadow: #747474 0px 1px 14px 0px;
    transition: height 300ms ease-out;
    z-index: 111;
}

.burgerLabel {
    margin-right: 12px;
}

.burgerIsOpen .burgerLabel {
    display: none;
}

.navigation {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
}

.activeLink {
    border-bottom: 2px solid white;
    margin-bottom: -1px;
}

.logo {
    margin-right: auto;
    cursor: pointer;
    text-decoration: none;
    color: white;
}



.phoneNumber {
    margin-right: 5%;
}



.burgerIcon.burgerIsOpen {
    margin-top: -12px;
}

.burgerIsOpen .bar1 {
    -webkit-transform: rotate(-45deg) translate(-8px, 6px);
    transform: rotate(-45deg) translate(-8px, 6px);
}

.burgerIsOpen .bar2{opacity: 0;}

.burgerIsOpen .bar3 {
    -webkit-transform: rotate(45deg) translate(-9px, -8px);
    transform: rotate(45deg) translate(-9px, -8px);
}

.bar1,.bar2,.bar3 {
    width: 35px;
    height: 5px;
    background-color: white;
    margin: 6px 0;
    transition: 0.4s;
}

.burgerIcon {
    display: none;
    cursor: pointer;
}

@media screen and (max-width: 500px) {
    .header {
        height: 90px;
        font-size: 20px;
    }

    .navigation {
        height: 50px;
    }
}


@media screen and (max-width: 360px) {
    .header {
        font-size: 18px;
    }
}


@media screen and (max-width: 820px) {
    .navigation>.headerButton {
        display: none;
    }
    .burgerIcon {
        float: right;
        display: flex;
        align-items: center;
        padding-left: 12px;
    }
}

@media screen and (max-width: 820px) {
    :global(.responsive) .navigation {
        flex-direction: column;
        margin-top: 20px;
    }
    .header:global(.responsive) .navigation>.headerButton {
        display: block;
        padding: 12px 0;
    }
    .header:global(.responsive) .navigation.linkLabel {
        font-size: 24px;
        display: inline;
    }
    .header:global(.responsive) .navigation>.headerButton:not(:first-child):not(:last-child) {
        text-align: center;
        width: 100%;
    }
    .header:global(.responsive) .navigation .logo {
        display: none;
    }
    .header:global(.responsive) {
        position: fixed;
        height: 100%;
    }
    :global(.responsive) .navigation > .burgerIcon {
        position: absolute;
        right: 0;
        top: 58px;
    }
}

@media (max-width: 1440px) {
    .burgerIcon.burgerIsOpen {
        margin-right: 24px;
    }
}

@media (max-width: 400px) {
    .burgerIcon.burgerIsOpen {
        margin-right: 12px;
    }
}

